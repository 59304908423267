import { useState, useEffect, useRef } from 'react';
import axios from 'axios'

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import 'bootstrap/dist/css/bootstrap.min.css';
import './EstiloMenu.css'
import { Facebook, Whatsapp, TelephoneFill, Instagram, ArrowLeft } from 'react-bootstrap-icons';

//import mipsa from './cliente-mipsa.png'
//import { useState, useEffect, useRef } from 'react';
function Values() {
    return (
      <>
        <div className="banner-bg-services" align="center">
          <Container>
            <br/>
            <br/>
            <br/>
            <br/>
            <div align="left" className='nav-back' onClick={ () => window.location.href = '/' }> <ArrowLeft />&nbsp; <span> Atrás </span> </div>
          </Container>
          <div className='section'>
            <Container>
              <Row>
                <Col>
                  <div className='banner-subtitle'> Nuestros Valores </div> <br/><br/>
                  <div className='banner-desc'> Brindar la <b>Ética</b> de nuestros servicios por el bienestar del cliente; siempre con la <b>Honestidad</b> en los resultados y las estrategias que en conjunto con las necesidades de su empresa se pueda trazar la <b>Objetividad</b> con los resultados de <b>Calidad</b> y bienestar por una relación bilateral, logrando en conjunto la <b>Confiabilidad</b> de las soluciones y valores aportados. </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
        
    );
}
export default Values;


