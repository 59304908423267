import { useState, useEffect, useRef } from 'react';
import axios from 'axios'

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import 'bootstrap/dist/css/bootstrap.min.css';
import './EstiloMenu.css'

import hands from './img/hands.png'
import client from './img/client.png'
import { ArrowLeft } from 'react-bootstrap-icons';

import blog1 from './img/blog/b1.jpg'

//import mipsa from './cliente-mipsa.png'
//import { useState, useEffect, useRef } from 'react';
function Next() {
  const [isLoading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [comments, setComments] = useState("");
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);
 
      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
 
      if (isScriptExist && callback) callback();
    }
 
    // load the script by passing the URL
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=6Le7o4UhAAAAAMtKSidIoVlcrI9n29WAI5ukwSfM`, function () {
      console.log("Script loaded!");
    });
  }, []);

  const handleOnChange = (event) => {
    var inputName = "set" + event.target.name.charAt(0).toUpperCase() + event.target.name.slice(1);
    eval(inputName)(event.target.value)
  }

  const handleShow = () => setShow(true);

  const handleOnSubmit = (event) => {
    event.preventDefault();
    
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute("6Le7o4UhAAAAAMtKSidIoVlcrI9n29WAI5ukwSfM", { action: 'submit' }).then(token => {
        //if(isVerified){
        setLoading(true);
        //const recaptchaValue = recaptchaRef.current.getValue();
        var form = document.getElementById("contactForm");
        var formData = new FormData(form);
        formData.append('recaptcha_response', token);

        setLoading(true);
        axios.post("https://digital-eureka.com/sv_mailer.php", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
          }).then(function(response) {

            if(response.data == "FAIL" || response.data == "ERROR") {
              setLoading(false)
              setSuccess(false)
              handleShow()
            } else {
              setLoading(false)
              setSuccess(true)
              handleShow()

              setName("");
              setPhone("");
              setEmail("");
              setComments("");
            }

          }).catch(function(err) {
            setLoading(false)
            setSuccess(false)
            handleShow()
          });
      //} else 
        //alert("Por favor, verifica que no eres un robot")
      });
    });
  }
    return (
      <div className="banner-bg-services" align="center">
        <Container>
          <br/>
          <br/>
          <br/>
          <br/>
          <div align="left" className='nav-back' onClick={ () => window.location.href = '/cursos' }> <ArrowLeft />&nbsp; <span> Atrás </span> </div>
        </Container>
        <div className='section'>
          <Container>
            <Row>
              <div className='banner-title-page'> Próximos cursos </div><br/>
            </Row>
            <br/><br/><br/><br/>
            <Row className='align-items-center'>
              <Col md={ 6 }>
                <div className='generic-image' style={{ backgroundImage: 'url(' + blog1 + ')' }}></div>
              </Col>
              <Col md={ 6 } align="left">
                <div className='banner-title-small-new'> TIPS PARA MEJORAR TU EMPRESA </div>
                <div className='banner-desc-small'> 5 de mayo 2024 </div>
                <div className='banner-desc-small'> Dirección - </div><br/>
                <br/>
                <div className='banner-desc-small'> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </div><br/>
              </Col>
            </Row>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <Row>
              <Col>
                <div className="banner-text-container">
                <div className='banner-title'>¡Me interesa un curso!</div><br/>
                <div className='banner-desc banner-desc-gold'>Te proporcionaremos cualquier información para tu inscripción</div><br/>
                <div>
                  <Form id="contactForm" onSubmit={handleOnSubmit}>
                    <Container className='img-50'>
                      <Row className="align-items-center">
                        <Col>
                          <div className="col-md-12 col-sm-12 col-xs-12" style={{textAlign: "left"}}>
                            <Form.Group controlId="formGridEmail" className='mb-3'>
                              <Form.Control required name="name" value={name} placeholder="Nombre:" onChange={handleOnChange}/>
                            </Form.Group>

                            <Form.Group controlId="formGridEmail" className='mb-3'>
                              <Form.Control required type="number" value={phone} name="phone" placeholder="Teléfono:" onChange={handleOnChange}/>
                            </Form.Group>

                            <Form.Group controlId="formGridEmail" className='mb-3'>
                              <Form.Control required type="email" value={email} name="email" placeholder="Email:" onChange={handleOnChange}/>
                            </Form.Group>

                            <Form.Group controlId="formGridEmail" className='mb-3'>
                              <Form.Control required name="comments" as="textarea" value={comments} rows="4" placeholder="Asunto:" onChange={handleOnChange}/>
                            </Form.Group>
                            <br/>

                            <div align="right">
                              <Button disabled={isLoading} type="submit" className='btn-submit-brown'>{isLoading ? 'Enviando…' : 'Enviar'}</Button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </Form>
                </div>
              </div>
              </Col>
            </Row>
            
          </Container>
        </div>
      </div>
        
    );
}
export default Next;


