import { useState, useEffect, useRef } from 'react';
import axios from 'axios'

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import 'bootstrap/dist/css/bootstrap.min.css';
import './EstiloMenu.css'
import { Facebook, Whatsapp, TelephoneFill, Instagram, ArrowLeft } from 'react-bootstrap-icons';

import blog1 from './img/blog/b1.jpg'

//import mipsa from './cliente-mipsa.png'
//import { useState, useEffect, useRef } from 'react';
function Knows() {
  const [ detail, setDetail ] = useState( false )
    return (
      <>
        { !detail &&
          <div className="banner-bg-services-b" align="center">
            <Container>
              <br/>
              <br/>
              <br/>
              <br/>
              <div align="left" className='nav-back' onClick={ () => window.location.href = '/' }> <ArrowLeft />&nbsp; <span> Atrás </span> </div>
            </Container>
            <div className='section'>
              <Container>
                <Row>
                  <Col>
                    <div className='banner-title-page'> ¿Sabías que? </div><br/><br/><br/><br/>

                    <div className='banner-desc'> Conoce noticias y datos interesantes que suceden en el país para estar al día con todo el ámbito de la contaduría. </div>
                  </Col>
                </Row>
              </Container>
              <br/><br/><br/><br/>

              <Container>
                <Row className='align-items-center' onClick={ () => setDetail( true ) } style={ { cursor: 'pointer' } }>
                  <Col md={ 6 }>
                    <div className='generic-image' style={{ backgroundImage: 'url(' + blog1 + ')' }}></div>
                  </Col>
                  <Col md={ 6 } align="left">
                    <div className='banner-title-small-new'> ¿COMO AFECTA LA RECESIÓN ECONÓMICA MIS INVERSIONES? </div> <br/>
                    <div className='banner-desc-small'> Los principales indicadores para que esto ocurra son los incrementos de precios e inflación; esto afecta para consumidores y empresas </div><br/>
                    <div className='banner-desc-small' align="right"> <b>Leer más...</b> </div>
                  </Col>
                </Row>
                <br/><br/>
                <Row className='align-items-center' onClick={ () => setDetail( true ) } style={ { cursor: 'pointer' } }>
                  <Col md={ 6 }>
                    <div className='generic-image' style={{ backgroundImage: 'url(' + blog1 + ')' }}></div> 
                  </Col>
                  <Col md={ 6 } align="left">
                  <div className='banner-title-small-new'> ¿COMO AFECTA LA RECESIÓN ECONÓMICA MIS INVERSIONES? </div> <br/>
                    <div className='banner-desc-small'> Los principales indicadores para que esto ocurra son los incrementos de precios e inflación; esto afecta para consumidores y empresas </div><br/>
                    <div className='banner-desc-small' align="right"> <b>Leer más...</b> </div>
                  </Col>
                </Row>
                <br/><br/>
                <Row className='align-items-center' onClick={ () => setDetail( true ) } style={ { cursor: 'pointer' } }>
                  <Col md={ 6 }>
                    <div className='generic-image' style={{ backgroundImage: 'url(' + blog1 + ')' }}></div>
                  </Col>
                  <Col md={ 6 } align="left">
                  <div className='banner-title-small-new'> ¿COMO AFECTA LA RECESIÓN ECONÓMICA MIS INVERSIONES? </div> <br/>
                    <div className='banner-desc-small'> Los principales indicadores para que esto ocurra son los incrementos de precios e inflación; esto afecta para consumidores y empresas </div><br/>
                    <div className='banner-desc-small' align="right"> <b>Leer más...</b> </div>
                  </Col>
                </Row>
              </Container>

            </div>

          </div>
        }

        { detail &&
          <div className="banner-bg-services-b" align="center">
            <Container>
              <br/>
              <br/>
              <br/>
              <br/>
              <div align="left" className='nav-back' onClick={ () => setDetail(false) }> <ArrowLeft />&nbsp; <span> Atrás </span> </div>
              <div className='section'>
                <Container>
                  <Row>
                    <Col>
                      <div className='banner-title-page' style={{ textAlign: 'left', textTransform: 'uppercase', color: '#000' }}> ¿COMO AFECTA LA RECESIÓN ECONÓMICA MIS INVERSIONES? </div>
                      <br/><br/>
                      <div className='banner-desc' style={{ textAlign: 'left', color: '#000' }}>
                        <div> Los principales indicadores para que esto ocurra son los incrementos de precios e inflación; esto afecta para consumidores y empresas </div>
                        <br/>
                        <div align="center"> <img src={ blog1 } style={{ width: '80%' }} /></div>
                        <br/>
                        <div> En 1974 se describió como una recesión a “dos trimestres consecutivos de crecimiento decreciente”, esto por el economista estadunidense Julius Shiskin, que aún varios países siguen usando este término.</div>
                        <br/>
                        <div>La recesión económica es un descenso significativo de la actividad económica que dura más de unos pocos meses, en esta se ve una reducción de producción de bienes y servicios y reduce la inversión, como consecuencia aumenta la falta de generación de empleos y la disponibilidad de dinero.</div><br/>
                        <div><b>Señal de una recesión</b></div><br/>
                        <div>Antes de una recesión usualmente hay un periodo de incremento económico, incrementos de precios, sobreproducción e inflación.</div>
                        <div>Hay que recordar que estos cambios afectan tanto para consumidores como para las empresas.</div><br/>
                        <div><b>Causas de una recesión</b></div><br/>
                        <div><b>Sobreoferta:</b></div><br/>
                        <div>Cuando hay más productos en el mercado de los que la gente puede comprar, las empresas reducen su producción. Esto significa que necesitan menos trabajadores, lo que lleva a despidos. Menos empleo significa menos dinero en los bolsillos de la gente, lo que a su vez reduce el consumo.</div><br/>
                        <div><b>Especulación:</b></div><br/>
                        <div>Los inversores compran un activo con la intención de obtener ganancias y esto hace que su precio suba de manera exagerada, llega un momento en que habrá más personas tratando de vender ese activo que personas dispuestas a comprarlo.</div><br/>
                        <div><b>Duda</b></div><br/>
                        <div>Se posponen decisiones de inversión de empresas y familias en momentos de sospecha</div><br/>
                        <div><b>¿Cómo me puedo prevenir para una recesión?</b></div><br/>
                        <div><b>Aumentar nuestra productividad</b></div><br/>
                        <div>Ahorrar e invertir bien nuestros recursos puede ayudarnos a proteger nuestro dinero durante una recesión</div><br/>
                        <div> En resumen, la recesión perjudica económicamente a muchas situaciones en un país, inversión, actividad económica, consumo y producción de bienes provocando que el PIB se reduzca ocasionando el aumento de la tasa de paro, cierre de empresas y pérdida de poder adquisitivo.</div><br/>
                      </div>
                      
                    </Col>
                  </Row>
                </Container>
                <br/>
              </div>
            </Container>
          </div>
        }
      </>
        
    );
}
export default Knows;


