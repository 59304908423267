import { useState, useEffect, useRef } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from './Home';
import Services from './Services';
import Values from './Values';
import Us from './Us';
import Course from './Course';
import Knows from './Knows';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import 'bootstrap/dist/css/bootstrap.min.css';
import './EstiloMenu.css'

import { Facebook, Whatsapp, TelephoneFill, Instagram } from 'react-bootstrap-icons';

import logo_footer from './img/logofooter.png'
import Clients from './Clients';
import Contact from './Contact';
import Next from './Next';
import ServiceDetail from './ServiceDetail';

function App() {

  return (
    <div className="App">
      <Navbar fixed="top" expand="lg" className='Menu-letras'>
        <Container>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" className='navbar-coll'>
            <Nav className='m-auto'>
              <Nav.Link  href="/"class="nav-link">Inicio</Nav.Link>
              <Nav.Link  href="/nosotros"class="nav-link">Nosotros</Nav.Link>
              <Nav.Link href="/valores" class="nav-link">Valores</Nav.Link>
              <Nav.Link href="/servicios" class="nav-link">Servicios</Nav.Link>
              <Nav.Link href="/sabiasque" class="nav-link">¿Sabías que?</Nav.Link>
              <Nav.Link href="/cursos" class="nav-link">Cursos</Nav.Link>
              <Nav.Link href="/clientes" class="nav-link">Clientes</Nav.Link>
              <Nav.Link href="/contacto" class="nav-link">Asesoria gratuita</Nav.Link>                        
              <Nav.Link href="/contacto" class="nav-link">Contacto</Nav.Link>
            </Nav>          
          </Navbar.Collapse>      
        </Container>
      </Navbar>  
      
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/servicios" element={<Services />} />
          <Route path="/valores" element={<Values />} />
          <Route path="/nosotros" element={<Us />} />
          <Route path="/cursos" element={<Course />} />
          <Route path="/sabiasque" element={<Knows />} />
          <Route path="/clientes" element={<Clients />} />
          <Route path="/contacto" element={<Contact />} />
          <Route path="/proxcursos" element={<Next />} />
          <Route path="*" element={<ServiceDetail />} />
        </Routes>
      </BrowserRouter>     

      <div className='footer'>
        <div>
          <Container>
            <Row>
              <Col><div className='banner-subtitle' align="left"> Contáctanos </div><br/><br/></Col>
            </Row>
            <Row className='align-items-center'>
              <Col md={ 4 } align='center'>
                <Whatsapp className='footer-icon' /> &nbsp;&nbsp; <span className='footer-text-big'> 56 2464 9902 </span>
              </Col>
              <Col md={ 4 } align='center'>
                <TelephoneFill className='footer-icon' /> &nbsp;&nbsp; <span className='footer-text-big'> 55 1122 3344 </span>
              </Col>
              <Col md={ 4 } align='center'>
                <TelephoneFill className='footer-icon' /> &nbsp;&nbsp; <span className='footer-text-big'> 55 1122 3344 </span>
              </Col>
            </Row>
            <br/><br/>
            <Row className='align-items-center'>
              <Col md={ 4 } align='center'>
                <div className='banner-desc-small'> Plaza Capri sexto piso, andador París 24 Centro urbano, Cuautitlán Izcalli </div>
              </Col>
              <Col md={ 4 } align='center'>
                <div className='banner-desc-small'> s.v.b.despacho@gmail.com </div>
                <div className='banner-desc-small'> s.v.b.facturas@gmail.com </div>
              </Col>
              <Col md={ 4 } align='center'>
                <br/>
                <div> <Facebook className='footer-icon'/> &nbsp;&nbsp; <Instagram className='footer-icon'/> </div>
                <div className='banner-desc-small'> Síguenos en redes sociales </div>
              </Col>
            </Row>
          </Container>
          <br/><br/><br/>
        </div>
        <div align="center"> <img src={ logo_footer } className='footer-logo' /> </div>
      </div>
    </div>
  );
}

export default App;
