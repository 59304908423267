import { useState, useEffect, useRef } from 'react';
import axios from 'axios'

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Card, Form } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import confiabilidad from './img/valores-confiabilidad.jpg'
import etica from './img/valores-etica.jpg'
import logo from './img/logo_empresa.png'
import honestidad from './img/valores-honestidad.jpg'
import calidad from './img/valores-calidad.jpg'
import objetividad from './img/valores-objetividad.jpg'
import akuaul from './img/cliente-akuaul.jpg'
import arca from './img/cliente-arca.jpg'
import dimesa from './img/cliente-dimesa.png'
import recplay from './img/cliente-recplay.jpg'
import mipsa from './img/cliente-mipsa.png'
import mision from './img/mision.jpg'
import vision from './img/vision.jpg'
import nicplastic from './img/clientes-nicplastic.png'

import justicia from './img/justicia.png'
import asesoria from './img/asesoria.png'
import contabilidad from './img/contabilidad.png'
import impuestos from './img/impuestos.png'
import salud from './img/salud.png'
import seguro from './img/seguro.png'
import factura from './img/factura.png'
import sat from './img/sat.png'
import nomina from './img/nomina.png'
import soporte from './img/soporte.jpg'
import marketing from './img/marketing.png'
import airplane from './img/airplane.png'
import hands from './img/hands.png'

import 'bootstrap/dist/css/bootstrap.min.css';
import './EstiloMenu.css'
import { Facebook, Whatsapp, TelephoneFill, Instagram } from 'react-bootstrap-icons';

//import mipsa from './cliente-mipsa.png'
//import { useState, useEffect, useRef } from 'react';
function Services() {
    return (
      <div className="banner-bg-services" align="center">

        <div className='section' >
          <div className='banner-title-jumbo'> ¿Cómo te podemos <b>ayudar</b>? </div>
                    
          <div className='section-top'>
            <Container style={{ cursor: 'pointer' }}>
              <Row>
                <Col md={ 3 } xs={ 6 }>
                  <Card onClick={ () => window.location.href = '/fiscal' }>
                    <Card.Body>
                      <div align="center"> <img src={ contabilidad } className="service-icons" /> </div>
                      <Card.Title>Planeación contable-fiscal</Card.Title>
                    </Card.Body>
                  </Card>
                </Col>

                <Col md={ 3 } xs={ 6 }>
                  <Card onClick={ () => window.location.href = '/obligaciones' }>
                    <Card.Body>
                      <div align="center"> <img src={ impuestos } className="service-icons" /> </div>
                      <Card.Title>Determinación de obligaciones fiscales</Card.Title>
                    </Card.Body>
                  </Card>
                </Col>

                <Col md={ 3 } xs={ 6 }>
                  <Card onClick={ () => window.location.href = '/facturacion' }>
                    <Card.Body>
                      <div align="center"> <img src={ factura } className="service-icons" /> </div>
                      <Card.Title>Facturación</Card.Title>
                    </Card.Body>
                  </Card>
                </Col>

                <Col md={ 3 } xs={ 6 }>
                  <Card onClick={ () => window.location.href = '/nominas' }>
                    <Card.Body>
                      <div align="center"> <img src={ nomina } className="service-icons" /> </div>
                      <Card.Title>Nóminas</Card.Title>
                    </Card.Body>
                  </Card>
                </Col>

                <Col md={ 3 } xs={ 6 }>
                  <Card onClick={ () => window.location.href = '/tramites' }>
                    <Card.Body>
                      <div align="center"> <img src={ sat } className="service-icons" /> </div>
                      <Card.Title>Trámites</Card.Title>
                    </Card.Body>
                  </Card>
                </Col>

                <Col md={ 3 } xs={ 6 }>
                  <Card onClick={ () => window.location.href = '/asesoria' }>
                    <Card.Body>
                      <div align="center"> <img src={ asesoria } className="service-icons" /> </div>
                      <Card.Title>Gestoría</Card.Title>
                    </Card.Body>
                  </Card>
                </Col>

                <Col md={ 3 } xs={ 6 }>
                  <Card onClick={ () => window.location.href = '/litigio' }>
                    <Card.Body>
                      <div align="center"> <img src={ justicia } className="service-icons" /> </div>
                      <Card.Title>Servicios en derecho</Card.Title>
                    </Card.Body>
                  </Card>
                </Col>

                <Col md={ 3 } xs={ 6 }>
                  <Card onClick={ () => window.location.href = '/marketing' }>
                    <Card.Body>
                      <div align="center"> <img src={ marketing } className="service-icons" /> </div>
                      <Card.Title>Diseño y marketing</Card.Title>
                    </Card.Body>
                  </Card>
                </Col>
                
                <Col md={ 12 } xs={ 6 }>
                  <Card onClick={ () => window.location.href = '/viajes' }>
                    <Card.Body>
                      <div align="center"> <img src={ airplane } className="service-icons" /> </div>
                      <Card.Title>Planeación de viajes turísticos</Card.Title>
                    </Card.Body>
                  </Card>
                </Col>
                
              </Row>
            </Container>

          </div>
        </div>
        {/*<div className="banner-bg-services" align="center">
          <br/><br/>
          <div className='section'>
            <Container>
              <Row>
                <Col>
                  <div className='banner-title'> Servicios </div><br/>
                </Col>
              </Row>
            </Container>
          </div>

          <div align="left">
            <Container>
              <Row className='service-row-margin align-items-center'>
                <Col md={ 'auto' }> <img src={ contabilidad } className="service-icon" /> </Col>
                <Col className='capital-gold'>
                  <div className='banner-title-small capital-gold'> Planeación contable- fiscal </div><br/>
                  <div>
                    <ul>
                      <li>Diseño y estructura contable</li>
                      <li>Elaboracion de contabilidad legal</li>
                      <li>Elaboracion y presentación de contabilidad electrónica</li>
                      <li>Determinacion y planeación de resultados contables-fiscales</li>
                      <li>Determinacion y presentación de declaraciones fiscales</li>
                    </ul>
                  </div>
                </Col>
              </Row>

              <Row className='service-row-margin align-items-center'>
                <Col md={ 'auto' }> <img src={ impuestos } className="service-icon" /> </Col>
                <Col className='capital-gold'>
                  <div className='banner-title-small capital-gold'> Determinación de obligaciones sociales </div><br/>
                  <div>
                    <ul>
                      <li>Federales IMSS, INFONAVIT, FONACOT entre otras</li>
                      <li>Estatales impuesto de turismo, sobre nóminas, entre otros</li>
                      <li>Manejo de IDSE (altas y bajas ante IMSS)</li>
                      <li>Conciliaciones institucionales</li>
                      <li>Alta patronal</li>
                      <li>Presentación de RT</li>
                      <li>Presentación de SISUB e ICSOE</li>
                    </ul>
                  </div>
                </Col>
              </Row>

              <Row className='service-row-margin align-items-center'>
                <Col md={ 'auto' }> <img src={ factura } className="service-icon" /> </Col>
                <Col className='capital-gold'>
                  <div className='banner-title-small capital-gold'> Facturación </div><br/>
                  <div>
                    <ul>
                      <li>Servicio de facturación a terceros</li>
                      <li>Servicio de carta porte o complemento</li>
                      <li>Servicio de control de inventarios</li>
                      <li>Entre otros</li>
                    </ul>
                  </div>
                </Col>
              </Row>

              <Row className='service-row-margin align-items-center'>
                <Col md={ 'auto' }> <img src={ nomina } className="service-icon" /> </Col>
                <Col className='capital-gold'>
                  <div className='banner-title-small capital-gold'> Nominas </div><br/>
                  <div>
                    <ul>
                      <li>Analisis de R.H.</li>
                      <li>Planeacion de nominas</li>
                      <li>Asesoria en el manejo integral de las nominas</li>
                      <li>Elaboración y manejo de nominas</li>
                    </ul>
                  </div>
                </Col>
              </Row>

              <Row className='service-row-margin align-items-center'>
                <Col md={ 'auto' }> <img src={ nomina } className="service-icon" /> </Col>
                <Col className='capital-gold'>
                  <div className='banner-title-small capital-gold'> Trámites </div><br/>
                  <div>
                    <ul>
                      <li>Altas, suspensiones ante el SAT</li>
                      <li>Padron de importación</li>
                      <li>Trámites de aclaraciones devoluciones</li>
                      <li>Trámites ante PRODECOM</li>
                    </ul>
                  </div>
                </Col>
              </Row>

              <Row className='service-row-margin align-items-center'>
                <Col md={ 'auto' }> <img src={ asesoria } className="service-icon" /> </Col>
                <Col className='capital-gold'>
                  <div className='banner-title-small capital-gold'> Asesoría jurídica y fiscal </div><br/>
                  <div>
                    <ul>
                      <li>Elaboración de proyecto constitutivo</li>
                      <li>Asesoría de entidad mercantil</li>
                      <li>Elaboración de proyectos de asambleas</li>
                      <li>Protocolizaciones y registros</li>
                      <li>Dictaminación IMSS, Infonavit y erogaciones </li>
                      <li>Planeación fiscal-contable</li>
                      <li>Defensa tributaria</li>
                    </ul>
                  </div>
                </Col>
              </Row>

              <Row className='service-row-margin align-items-center'>
                <Col md={ 'auto' }> <img src={ justicia } className="service-icon" /> </Col>
                <Col className='capital-gold'>
                  <div className='banner-title-small capital-gold'> Servicios en Derecho (Litigio) </div><br/>
                  <div>
                    <ul>
                      <li>Civil y mercantil</li>
                      <li>Penal</li>
                      <li>Laboral</li>
                      <li>Familiar</li>
                      <li>Administrativo</li>
                      <li>Fiscal</li>
                      <li>Servicios Ambientales</li>
	                    <li>Servicios Ambientales en el Sector Inmobiliario</li>
                    </ul>
                  </div>
                </Col>
              </Row>

              <Row className='service-row-margin align-items-center'>
                <Col md={ 'auto' }> <img src={ marketing } className="service-icon" /> </Col>
                <Col className='capital-gold'>
                  <div className='banner-title-small capital-gold'> Diseño y marketing </div><br/>
                  <div>
                    <ul>
                      <li>Identidad de marca</li>
                      <li>Contenidos para redes sociales</li>
                      <li>Edición de fotografía</li>
                      <li>Edición de video</li>
                    </ul>
                  </div>
                </Col>
              </Row>

              <Row className='service-row-margin align-items-center'>
                <Col md={ 'auto' }> <img src={ airplane } className="service-icon" /> </Col>
                <Col className='capital-gold'>
                  <div className='banner-title-small capital-gold'> Planeación de viajes turísticos </div><br/>
                  <div>
                    <ul>
                      <li>-</li>
                    </ul>
                  </div>
                </Col>
              </Row>

            </Container>
          </div>
        </div>*/}
      </div>
        
    );
}
export default Services;


