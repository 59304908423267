import { useState, useEffect, useRef } from 'react';
import axios from 'axios'

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

import 'bootstrap/dist/css/bootstrap.min.css';
import './EstiloMenu.css'

import hands from './img/hands.png'
import client from './img/client.png'
import { ArrowLeft } from 'react-bootstrap-icons';

//import mipsa from './cliente-mipsa.png'
//import { useState, useEffect, useRef } from 'react';
function Course() {
    return (
      <div className="banner-bg-services" align="center">
        <Container>
          <br/>
          <br/>
          <br/>
          <br/>
          <div align="left" className='nav-back' onClick={ () => window.location.href = '/' }> <ArrowLeft />&nbsp; <span> Atrás </span> </div>
        </Container>
        <div className='section'>
          <Container>
            <Row>
              <div className='banner-title-page'> Construyendo el futuro </div><br/>
            </Row>
            <br/><br/><br/><br/>
            <Row>
              <Col md={ 5 }>
                <div> <img src={ hands } className='img-80'/> </div>
              </Col>
              <Col md={ 7 }>
                <div className='banner-subtitle' align="left"> Somos Agentes Capacitores Externos </div> <br/><br/>
                <div className='banner-desc-small' align="left"> 
                  <div>Estamos conscientes que en el mundo laboral existe mucha competencia y que cada vez tenemos que estar mejor preparados para lograr sobresalir. </div><br/>

                  <div>Es por eso por lo que <b>S&V Business are Business</b> está comprometido a compartir y apoyar mediante <b>Cursos Autorizados</b> por la Secretaría del Trabajo y Previsión Social como <b>Agente Capacitor Externo</b> bajo el número de folio <b>SAV180222PJ7-0013</b>. </div><br/>

                  {/*<div><b> Nuestros cursos </b></div><br/>

                  <div>Aspectos contables y fiscales que deben controlar los empresarios. </div><br/>

                  <div>Estrategias fiscales para empresarios. </div><br/>

                  <div align="right"> <Button type="submit" className='btn-submit-brown'> Conoce más... </Button> </div>*/}
                </div>
              </Col>
            </Row>
            <br/><br/><br/><br/>
            <Row className='align-items-center'>
              <Col md={ 7 }>
                <div className='banner-title' align="left"> 
                  <span className='banner-desc-gold'>"</span>La importancia de compartir el conocimiento el fundamental para un futuro brillante.<span className='banner-desc-gold'>"</span>
                </div>
              </Col>
              <Col md={ 5 }>
                <div> <img src={ client } className='img-80'/> </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className='banner-desc-small' align="left">
                  <div className='banner-title' align="center"> Cursos impartidos </div><br/>
                  <br/>
                  <ul>
                    <li>Aspectos contables y fiscales que deben conteolar los empresarios</li>
                    <li>Estrategias fiscales para empresarios</li>
                    <li>Planeación estrategica</li>
                    <li>Mapeo de procesos</li>
                    <li>Liderazgo para calidad</li>
                    <li>Planeación avanzada de calidad</li>
                    <li>Taller de integración y motivación para el cambio y el trabajo en equipo</li>
                    <li>Supervisión efectiva</li>
                    <li>Motivación para el cambio personal y organizacional</li>
                    <li>Curso - Taller "Fortalecimiento de la autoestima"</li>
                    <li>Curso - Taller: Trabajo en equipo</li>
                    <li>Curso: Calidad en el servicio al cliente</li>
                    <li>Administración por objetivos y resultados (APO/R)</li>
                  </ul>
                </div>
                <br/><br/>
                <div align="center"> <Button type="submit" className='btn-submit-brown' onClick={ () => window.location.href = '/proxcursos' }> Próximos cursos </Button> </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
        
    );
}
export default Course;


