import { useState, useEffect, useRef } from 'react';
import axios from 'axios'

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Card, Form } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import confiabilidad from './img/valores-confiabilidad.jpg'
import etica from './img/valores-etica.jpg'
import logo from './img/logo_empresa.png'
import honestidad from './img/valores-honestidad.jpg'
import calidad from './img/valores-calidad.jpg'
import objetividad from './img/valores-objetividad.jpg'
import akuaul from './img/cliente-akuaul.jpg'
import arca from './img/cliente-arca.jpg'
import dimesa from './img/cliente-dimesa.png'
import recplay from './img/cliente-recplay.jpg'
import mipsa from './img/cliente-mipsa.png'
import mision from './img/mision.jpg'
import vision from './img/vision.jpg'
import nicplastic from './img/clientes-nicplastic.png'

import justicia from './img/justicia.png'
import asesoria from './img/asesoria.png'
import contabilidad from './img/contabilidad.png'
import impuestos from './img/impuestos.png'
import salud from './img/salud.png'
import seguro from './img/seguro.png'
import factura from './img/factura.png'
import sat from './img/sat.png'
import nomina from './img/nomina.png'
import soporte from './img/soporte.jpg'
import marketing from './img/marketing.png'
import airplane from './img/airplane.png'
import hands from './img/hands.png'

import justicia_g from './img/justicia_g.png'
import asesoria_g from './img/asesoria_g.png'
import contabilidad_g from './img/contabilidad_g.png'
import impuestos_g from './img/impuestos_g.png'
import factura_g from './img/factura_g.png'
import sat_g from './img/sat_g.png'
import nomina_g from './img/nomina_g.png'
import marketing_g from './img/marketing_g.png'
import airplane_g from './img/airplane_g.png'

import 'bootstrap/dist/css/bootstrap.min.css';
import './EstiloMenu.css'
import { Facebook, Whatsapp, TelephoneFill, Instagram, ArrowLeft } from 'react-bootstrap-icons';

//import mipsa from './cliente-mipsa.png'
//import { useState, useEffect, useRef } from 'react';
const PATH = window.location.pathname;

function ServiceDetail() {
  const [ selected, setSelected ] = useState(null)
  const [ contabilidadIcon, setContabilidadIcon ] = useState(contabilidad)
  const [ impuestosIcon, setImpuestosIcon ] = useState(impuestos)
  const [ facturaIcon, setFacturaIcon ] = useState(factura)
  const [ nominaIcon, setNominaIcon ] = useState(nomina)
  const [ satIcon, setSatIcon ] = useState(sat)
  const [ asesoriaIcon, setAsesoriaIcon ] = useState(asesoria)
  const [ justiciaIcon, setJusticiaIcon ] = useState(justicia)
  const [ marketingIcon, setMarketingIcon ] = useState(marketing)
  const [ airplaneIcon, setAirplaneIcon ] = useState(airplane)

  useEffect(() => {
    if( PATH == '/fiscal' ) setContabilidadIcon( contabilidad_g )
    else if( PATH == '/obligaciones' ) setImpuestosIcon( impuestos_g )
    else if( PATH == '/facturacion' ) setFacturaIcon( factura_g )
    else if( PATH == '/nominas' ) setNominaIcon( nomina_g )
    else if( PATH == '/tramites' ) setSatIcon( sat_g )
    else if( PATH == '/asesoria' ) setAsesoriaIcon( asesoria_g )
    else if( PATH == '/litigio' ) setJusticiaIcon( justicia_g )
    else if( PATH == '/marketing' ) setMarketingIcon( marketing_g )
    else if( PATH == '/viajes' ) setAirplaneIcon( airplane_g )
  }, []);

    return (
      <div className="banner-bg-services-b" align="center">
        <br/>
        <br/>
        <br/>
        <div>
          <Container>
            <Row className='align-items-center' style={{ cursor: 'pointer' }}>
              <Col md={ 1 }></Col>
              <Col md={ 1 }>
                <div align="left" className='nav-back' onClick={ () => window.location.href = '/servicios' }> <ArrowLeft />&nbsp; <span> Atrás </span> </div>
              </Col>

              <Col md={ 1 } xs={ 6 }>
                <Card onClick={ () => window.location.href = '/fiscal' }>
                  <Card.Body>
                    <div align="center"> <img src={ contabilidadIcon } className="service-icons-detail" /> </div>
                    <Card.Title className='service-title'>Planeación contable-fiscal</Card.Title>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={ 1 } xs={ 6 }>
                <Card onClick={ () => window.location.href = '/obligaciones' }>
                  <Card.Body>
                    <div align="center"> <img src={ impuestosIcon } className="service-icons-detail" /> </div>
                    <Card.Title className='service-title'>Determinación de obligaciones fiscales</Card.Title>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={ 1 } xs={ 6 }>
                <Card onClick={ () => window.location.href = '/facturacion' }>
                  <Card.Body>
                    <div align="center"> <img src={ facturaIcon } className="service-icons-detail" /> </div>
                    <Card.Title className='service-title'>Facturación</Card.Title>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={ 1 } xs={ 6 }>
                <Card onClick={ () => window.location.href = '/nominas' }>
                  <Card.Body>
                    <div align="center"> <img src={ nominaIcon } className="service-icons-detail" /> </div>
                    <Card.Title className='service-title'>Nóminas</Card.Title>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={ 1 } xs={ 6 }>
                <Card onClick={ () => window.location.href = '/tramites' }>
                  <Card.Body>
                    <div align="center"> <img src={ satIcon } className="service-icons-detail" /> </div>
                    <Card.Title className='service-title'>Trámites</Card.Title>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={ 1 } xs={ 6 }>
                <Card onClick={ () => window.location.href = '/asesoria' }>
                  <Card.Body>
                    <div align="center"> <img src={ asesoriaIcon } className="service-icons-detail" /> </div>
                    <Card.Title className='service-title'>Gestoría</Card.Title>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={ 1 } xs={ 6 }>
                <Card onClick={ () => window.location.href = '/litigio' }>
                  <Card.Body>
                    <div align="center"> <img src={ justiciaIcon } className="service-icons-detail" /> </div>
                    <Card.Title className='service-title'>Servicios en derecho</Card.Title>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={ 1 } xs={ 6 }>
                <Card onClick={ () => window.location.href = '/marketing' }>
                  <Card.Body>
                    <div align="center"> <img src={ marketingIcon } className="service-icons-detail" /> </div>
                    <Card.Title className='service-title'>Diseño y marketing</Card.Title>
                  </Card.Body>
                </Card>
              </Col>
              
              <Col md={ 1 } xs={ 6 }>
                <Card onClick={ () => window.location.href = '/viajes' }>
                  <Card.Body>
                    <div align="center"> <img src={ airplaneIcon } className="service-icons-detail" /> </div>
                    <Card.Title className='service-title'>Planeación de viajes turísticos</Card.Title>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={ 1 }></Col>
            </Row>
          </Container>
        </div>

        <div className='section' style={{ color: '#68554a' }}>

          { PATH == '/obligaciones' &&
            <>
              <div className='banner-title-page'> Determinación de obligaciones fiscales </div>
              <br/><br/><br/>
              <div align="left">
                <div><b>Federales, IMSS, INFONAVIT, FONACOT, entre otras</b></div>
                <div>Obligaciones que tiene el patrón frente a las instituciones gubernamentales</div><br/>

                <div><b>Estatales, impuestos de turismo, sobre nóminas, entre otros</b></div>
                <div>Pago de impuestos sobre cualquier caso</div><br/>

                <div><b>Manejo de IDSE (altas y bajas ante IMSS)</b></div>
                <div>Alta patronal, incidencias, incapacidades, prima vacacional, etc.</div><br/>

                <div><b>Conciliaciones institucionales</b></div>
                <div>Revisión de documentación, identificación de diferencias, correcciones, prevención de fraude, etc.</div><br/>

                <div><b>Alta patronal</b></div>
                <div>Cumplimiento legal, derechos del trabajador, protección para el empleador, etc.</div><br/>

                <div><b>Presentación de RT</b></div><br/>
                <div><b>Presentación de SISUB e ICSOE</b></div>
              </div>
            </>  
          }
          
          { PATH == '/fiscal' &&
            <>
              <div className='banner-title-page'> Planeación contable - fiscal </div>
              <br/><br/><br/>
              <div align="left">
                <div><b>Diseño y estructura contable</b></div>
                <div>Establecer el catálogo de tipo de cuentas contables de acuerdo con las necesidades o expectativas que tenga nuestro cliente con su empresa</div><br/>

                <div><b>Elaboración de contabilidad legal</b></div>
                <div>Forma de aplicar los ingresos desglosados por región, por municipio o por punto de venta</div><br/>

                <div><b>Elaboración y presentación de contablidad electrónica</b></div>
                <div>Creación de carpetas digitales, balanzas de cuentas, información de poólizas, etc.</div><br/>

                <div><b>Determinación y planeación de resultados contables-fiscales</b></div>
                <div>Estructurar un camino eficaz de modo que se obtengan mejores resultados contables-fisales de acuerdo con las necesidades del cliente</div><br/>

                <div><b>Determinación y presentación de declaraciones fiscales</b></div>
                <div>Presentación de declaraciones fiscales, cálculos de impuestos, responder a notificaciones del SAT</div><br/>

                <div><b>Determinación de obligaciones sociales</b></div>
                <div>Idear un mejor plan sobre cuál es la mejor respuesta a sus obligaciones sociales</div><br/>
              </div>
            </>  
          }

          { PATH == '/facturacion' &&
            <>
              <div className='banner-title-page'> Facturación </div>
              <br/><br/><br/>
              <div align="left">
                <div><b>Servicio de facturación a terceros</b></div><br/>

                <div><b>Servicio de carta porte o complemento</b></div>
                <div>Seguridad a la hora de transportar mercancía</div><br/>

                <div><b>Servicio de control de inventarios</b></div>
                <div>Creación de inventarios para el mejor control de tu empresa</div><br/>

                <div><b>Entre otros...</b></div>
              </div>
            </>  
          }

          { PATH == '/nominas' &&
            <>
              <div className='banner-title-page'> Nominas </div>
              <br/><br/><br/>
              <div align="left">
                <div><b>Análisis de R.H.</b></div>
                <div>Entender y mejorar la eficacia de su gestión empresarial evaluando los datos relacionados con los empleados para un mejor desempeño interno laboral</div><br/>

                <div><b>Planeación de nominas</b></div>
                <div>Encontrar el mejor punto donde tu empresa lleve una mejor organización y aplicación de nominas</div><br/>

                <div><b>Asesoria en el manejo integral de las nominas</b></div>
                <div>Planeación de un mejor manejo del área de nóminas para elevar el potencial de tu empresa</div><br/>

                <div><b>Elaboración y manejo de nominas</b></div>
                <div>Pago de nominas</div><br/>
              </div>
            </>  
          }

          { PATH == '/tramites' &&
            <>
              <div className='banner-title-page'> Trámites </div>
              <br/><br/><br/>
              <div align="left">
                <div><b>Altas, suspensiones ante el SAT</b></div>
                <div>Cambio de situación fiscal, altas, inicio de actividades económicas, comprobaciones de cumplimiento, etc.</div><br/>

                <div><b>Padron de importación</b></div>
                <div>Registro de personas físicas o morales que deseen importar productos de mercancía al país</div><br/>

                <div><b>Trámites de aclaraciones devoluciones</b></div>
                <div>Conocer el mejor método para hacer devoluciones y aclaraciones fiscales ante el SAT</div><br/>

                <div><b>Trámites ante PRODECON</b></div>
                <div>Protección a los contribuyentes para garantizar que sus derechos sean respetados por las autoridades fiscales</div><br/>
              </div>
            </>  
          }

          { PATH == '/asesoria' &&
            <>
              <div className='banner-title-page'> Gestoría </div>
              <br/><br/><br/>
              <div align="left">
                <div><b>Elaboración de proyecto constitutivo</b></div><br/>

                <div><b>Asesoría de entidad mercantil</b></div><br/>

                <div><b>Elaboración de proyectos de asambleas</b></div><br/>

                <div><b>Protocolizaciones y registros</b></div><br/>

                <div><b>Dictaminación IMSS, Infonavit y erogaciones</b></div><br/>
                
                <div><b>Planeación fiscal-contable</b></div><br/>

                <div><b>Defensa tributaria</b></div><br/>
              </div>
            </>  
          }

          { PATH == '/litigio' &&
            <>
              <div className='banner-title-page'> Servicios en Derecho (Litigio) </div>
              <br/><br/><br/>
              <div align="left">
                <div><b>Civil y mercantil</b></div><br/>

                <div><b>Penal</b></div><br/>

                <div><b>Laboral</b></div><br/>

                <div><b>Familiar</b></div><br/>

                <div><b>Administrativo</b></div><br/>
                
                <div><b>Fiscal</b></div><br/>

                <div><b>Servicios Ambientales</b></div><br/>

                <div><b>Servicios Ambientales en el Sector Inmobiliario</b></div><br/>
              </div>
            </>  
          }

          { PATH == '/marketing' &&
            <>
              <div className='banner-title-page'> Diseño y marketing </div>
              <br/><br/><br/>
              <div align="left">
                <div><b>Identidad de marca</b></div>
                <div>Creación de marcas, identidad visual identidad corporativa, etc.</div><br/>

                <div><b>Contenidos para redes sociales</b></div>
                <div>Contenido para post, reels, tiktoks, publicidad digital, etc.</div><br/>

                <div><b>Edición de fotografía</b></div><br/>

                <div><b>Edición de video</b></div>
              </div>
            </>  
          }

          { PATH == '/viajes' &&
            <>
              <div className='banner-title-page'> Planeación de viajes turísticos </div>
              <br/><br/><br/>
              <div align="left">
                <div><b>Programes Terrestres Nacionales e Internacionales</b></div><br/>

                <div><b>Boletos de avión</b></div><br/>

                <div><b>Cruceros Nacionales e Internacionales</b></div><br/>

                <div><b>Asistencia en viajes</b></div>

                <div><b>Programas Especiales Nacionales e Iternacionales</b></div>
              </div>
            </>  
          }
        
        </div>
        
      </div>
        
    );
}
export default ServiceDetail;


