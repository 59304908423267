import { useState, useEffect, useRef } from 'react';
import axios from 'axios'

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import 'bootstrap/dist/css/bootstrap.min.css';
import './EstiloMenu.css'

import logosv from './img/logosv.png'
import line01 from './img/line01.png'
import line02 from './img/line02.png'
import line03 from './img/line03.png'
import { ArrowLeft } from 'react-bootstrap-icons';

//import mipsa from './cliente-mipsa.png'
//import { useState, useEffect, useRef } from 'react';
function Us() {
    return (
      <>
        <div className="banner-bg-services" align="center">
          <Container>
            <br/>
            <br/>
            <br/>
            <br/>
            <div align="left" className='nav-back' onClick={ () => window.location.href = '/' }> <ArrowLeft />&nbsp; <span> Atrás </span> </div>
          </Container>
          <div className='section'>
            <Container>
              <Row>
                <Col>
                  <div className='banner-subtitle'> Somos Servicios y Valores </div> <br/><br/>
                  <div className='banner-desc'> Somos una firma con 30 años de trayectoria pudiendo brindar y sumar esfuerzos entre el cliente y nosotros, exponenciando los servicios y valores a través del diseño de acciones integrales en el ámbito contable fiscal, redoblando soluciones en los ámbitos administrativos, financieros y legales de cada uno de nuestros clientes.  </div>
                </Col>
              </Row>
            </Container>
          </div>

          <div className='section'>
            <div className='banner-title-big'> Un servicio <b>diferente</b>  </div>
            <br/><br/><br/>
          
            <Container>
              <Row>
                <Col md={ 4 }>
                  <div className='banner-title-small'> Misión </div><br/>
                  <div className='banner-desc-small'> Establecer un vínculo con cada uno de nuestros clientes, para satisfacer sus necesidades con base a servicios de calidad y de excelencia siempre anteponiendo el profesionalismo y veracidad en los resutados.  </div>
                </Col>
                <Col md={ 4 }></Col>
                <Col md={ 4 }></Col>
              </Row>
              <Row>
                <Col md={ 4 } className='col-mobile-hide'>
                  <div align="right">
                    <img src={ line01 } className='img-50'/>
                  </div>
                </Col>
                <Col md={ 4 } className='col-mobile-hide'>
                  <div align="center">
                    <img src={ logosv } className='img-80'/>
                  </div>
                </Col>
                <Col md={ 4 }>
                  <br/>
                  <div className='banner-title-small'> Visión </div><br/>
                  <div className='banner-desc-small'> Ser una firma reconocida por su profesionalismo, honestidadm responsabilidad y formalidad en el área fiscal, contable, administrativa y de servicios.  </div>
                  <div align="left" className='col-mobile-hide'>
                    <img src={ line02 } className='img-50'/>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={ 4 }></Col>
                <Col md={ 4 } className='col-mobile-hide'>
                  <div align="center" style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                    <img src={ line03 } className='img-30'/>
                  </div>
                </Col>
                <Col md={ 4 }></Col>
              </Row>
              <br/>
              <Row>
                <Col md={ 4 }></Col>
                <Col md={ 4 }>
                  <div className='banner-title-small'> Objetivos </div><br/>
                  <div className='banner-desc-small epoligue-font capital-brown'> <span className='capital-style'>S</span>er un valor agregado a cada compañia y no ser un costo interminable.  </div>
                  <br/><br/>
                  <div className='banner-desc-small epoligue-font capital-gold'> <span className='capital-style'>V</span>alorar la realidad del cliente para convertirla en estrategias alcanzables.  </div>
                  <br/><br/>
                  <div className='banner-desc-small epoligue-font capital-brown'> <span className='capital-style'>S</span>er una línea en el tiempo con veracidad y resultados.  </div>
                  <br/><br/>
                  <div className='banner-desc-small epoligue-font capital-gold'> <span className='capital-style'>V</span>eracidad y transparecncia en las soluciones y en los valores de ética y profesionalismo.  </div>
                  <br/>
                </Col>
                <Col md={ 4 }></Col>
              </Row>
            </Container>
          </div>

          <div className='section'>
            <div className='banner-title-big'> <span className='capital-gold'>“</span>  Business are Business  <span className='capital-gold'>”</span>   </div>
          </div>
        </div>
      </>
    );
}
export default Us;