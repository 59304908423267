import { useState, useEffect, useRef } from 'react';
import axios from 'axios'

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import 'bootstrap/dist/css/bootstrap.min.css';
import './EstiloMenu.css'
import { Facebook, Whatsapp, TelephoneFill, Instagram, ArrowLeft } from 'react-bootstrap-icons';

import akuaul from './img/cliente-akuaul.jpg'
import arca from './img/cliente-arca.jpg'
import dimesa from './img/cliente-dimesa.png'
import recplay from './img/cliente-recplay.jpg'
import mipsa from './img/cliente-mipsa.png'

import iglesia from './img/iglesia.png'
import fersam from './img/fersam.png'
import wbc from './img/wbc.png'
import livec from './img/livec.png'
import medical from './img/medical.png'

//import mipsa from './cliente-mipsa.png'
//import { useState, useEffect, useRef } from 'react';
function Clients() {
    return (
      <>
        <div className="banner-bg-services" align="center">
          <Container>
            <br/>
            <br/>
            <br/>
            <br/>
            <div align="left" className='nav-back' onClick={ () => window.location.href = '/' }> <ArrowLeft />&nbsp; <span> Atrás </span> </div>
          </Container>
          <div className='section'>
            <Container className='clients-container'>
              <Row>
                <Col>
                  <div className='banner-title-page'> Nuestros clientes </div> <br/><br/>
                  <div className='banner-desc'> Algunas de nuetros clientes que nos han confiado sus empresas. </div>
                </Col>
              </Row>
              <br/><br/>
              <Row>
                <Col md={ 6 }  xs={ 6 } className='client-img' align="center">
                  <img src={wbc} class="card-img-top" alt="..."/>
                  <br/><br/>
                  <div>CONSEJO MUNDIAL DE BOX</div>
                </Col>
                <Col md={ 6 }  xs={ 6 } className='client-img' align="center">
                  <img src={iglesia} class="card-img-top" alt="..."/>
                  <br/><br/>
                  <div>IGLESIA MEXICANA DEL EVANGELIO DE CRSITO</div>
                </Col>
                <Col md={ 6 }  xs={ 6 } className='client-img' align="center">
                  <img src={recplay} class="card-img-top" alt="..."/>
                  <br/><br/>
                  <div>REC PLAY</div>
                </Col>
                <Col md={ 6 }  xs={ 6 } className='client-img' align="center">
                  <img src={arca} class="card-img-top" alt="..."/>
                  <br/><br/>
                  <div>SOLUCIONES ARCA AUTOMOTRIZ</div>
                </Col>
                <Col md={ 6 }  xs={ 6 } className='client-img' align="center">
                  <img src={livec} class="card-img-top" alt="..."/>
                  <br/><br/>
                  <div>LIVEC</div>
                </Col>
                <Col md={ 6 }  xs={ 6 } className='client-img' align="center">
                  <img src={medical} class="card-img-top" alt="..."/>
                  <br/><br/>
                  <div>BENROC</div>
                </Col>
                <Col md={ 6 }  xs={ 6 } className='client-img' align="center">
                  <img src={fersam} class="card-img-top" alt="..."/>
                  <br/><br/>
                  <div>GRUPO FERSAM</div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
        
    );
}
export default Clients;


